// topbar menu styles start
.topBarImage {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin: 0px;
    margin-bottom: 10px
}

body {
    font-family: 'Inter', sans-serif !important;
    font-size: 13px !important;
}

body .p-datatable .p-datatable-tbody>tr>td {
    padding: 0px 6px !important;
}

body .p-datatable .p-datatable-tbody>tr {
    height: 35px !important;
}

body .p-component {
    font-family: 'Inter', sans-serif !important;
}

.topBarImageAlignment {
    text-align: center;
}

body a {
    // color: $color-white;
    color: $color-blue !important;
}

body a:hover {
    color: $color-blue !important;
}

.followUp-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.followUp-content {
    background: white;
    padding: 20px;
    border-radius: 5px;
    position: relative;
    max-width: 1500px;
    width: 100%;
}

.followupheader {
    margin: 0;
    padding: 10px;
    font-size: 24px;
    color: #333;
}

body .p-datatable .p-sortable-column:not(.p-highlight):hover {
    background-color: $color-template
}

.theme-light .form input,
.theme-light .form textarea {
    color: black;
}

.themeColorText {
    color: #f17038;
}

.showingNumber {
    position: relative;
    top: 4px;
}

.paginationcss {
    margin-right: 0;
}

.paddingForHeader {
    padding: 0.571em 1em;
}

.topbarMenu {
    width: 200px;
    border-radius: 0;
    border: none;
    padding-bottom: 15px 0;
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.05);
    margin-top: 0;
}

.theme-white {
    // border: 1px solid #ddd !important;
    background: white !important
}

.iconstyle {
    background: #d6d7da !important;
    border: 1px solid #d6d7da !important;
}

.analystCheckbox {
    margin-top: 0px !important;
}


.clearIcon {
    width: unset !important;
    cursor: pointer;
    float: right;

    .disabled {
        color: $color-disabled;
    }

    .enabled {
        color: red
    }
}

.pe_left .p-autocomplete-loader {
    width: 0%;
    display: none;
}

// topbar menu styles end
.form input {
    background: white;
    // margin-bottom: 0.5rem;
}

.form textarea {
    min-height: 92px !important;
    color: black !important;
}

.custom_text textarea {
    min-height: 55px !important;
    width: 100% !important;
}

body .p-datatable .p-datatable-tbody>tr.p-highlightHoliday {
    background-color: #fbff2b;
}

body .p-datatable .p-datatable-tbody>tr.p-highlightWeekend {
    background-color: #1efd00;
}

body .p-datatable .p-datatable-tbody>tr.p-highlightLessThan6Hrs {
    background-color: #ff0000;
}

body .p-datatable .p-sortable-column .p-sortable-column-icon {
    color: $color-template, !important;
    ;
    font-size: 1.5em !important;
    padding-left: 10px;
}

body .p-fluid .p-autocomplete .p-autocomplete-dropdown.p-button {
    width: 2em;
}

body .p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container .p-autocomplete-input-token input {
    font-size: 12px;
}

//$color-template to color-white
body .p-button {
    // background-color: $color-white;
    // border-color:$color-white
    background-color: $color-blue;
    border-color: $color-blue
}

.date-picker .react-datepicker__day--keyboard-selected {
    background-color: $color-blue!important ;
}

.date-picker .react-datepicker__day--selected,
.date-picker .react-datepicker__day--in-range,
.date-picker .react-datepicker__time-list-item--selected {
    background-color: $color-blue!important ;
}

body .p-button:hover {
    // background-color: $color-white;
    // border-color:$color-white
    background-color: $color-blue !important;
    border-color: $color-blue !important;
}

//autocomplete multi-select
.p-autocomplete-input-token {
    height: 28px;
    width: 300px;
}

.p-autocomplete-token.p-highlight {
    margin-bottom: 2px;
}

// Dasboard 
.dashBoardCardWithScroll {
    height: 390px;
}

.p-column-title {
    text-transform: capitalize;
}

.sessionExpire {
    margin: "0px";
    position: absolute;
    top: 40%;
    left: 45%;
}

//Scrollable table with Sticky Header
.table-fixed tbody {
    height: 200px;
    overflow-y: auto;
    width: 100%;
}

.table-fixed thead,
.table-fixed tbody,
.table-fixed tr,
.table-fixed td,
.table-fixed th {
    display: block;
}

.table-fixed tr:after {
    content: "";
    display: block;
    visibility: hidden;
    clear: both;
}

.table-fixed tbody td,
.table-fixed thead>tr>th {
    float: left;
}

//kpiTable
.kpiTable {
    background-color: $color-white;
    color: black;
}

.colorLegend {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 10px;
}

body .p-multiselect {
    border: 1px solid #cfd0d2 !important
}

body .p-multiselect-panel .p-multiselect-items {
    color: black
}

body .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
    background-color: $sidebar-Hover;
    color: black !important;
}

body .p-checkbox .p-checkbox-box.p-highlight {
    background-color: $color-blue;
    border-color: $color-blue;

}

body .p-checkbox {
    width: 17px !important;
}

body .p-input-overlay-visible {
    z-index: 9999 !important;
    // left: 676.188px  !important;
}

body .deal_managements .p-input-overlay-visible {
    min-width: 100px !important
}

.color-picker__popover {
    z-index: 9999;
}

.calendar .rbc-off-range-bg {
    background-color: #e5e5e5;
}

.textElipses {
    font-weight: 500;
    color: $color-blue;
    cursor: pointer;
}

.p-dropdown .p-dropdown-trigger {
    height: 100%;
}

// .css-vj8t7z{
//     min-height: 0 !important
// }
// .css-1hwfws3{
//     position: static !important;
// }
// .css-10nd86i react-select{
//     height: 33px
// }
// .css-2o5izw .react-select__control .react-select__control--is-focused{
//     height: 33px;
// }
.react-select__menu-list {
    max-height: 150px !important;
}

.botmessagesText {
    padding: 5px;
    background-color: rgba(0, 0, 0, 0.1);
    padding-top: 8px;
    padding-left: 7px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 8px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    margin-left: 5px;
    align-self: flex-start;
    width: 90%;
    border-color: red
}

.usermessagesText {
    background-color: #f0f1ef;
    padding-top: 5px;
    padding-left: 7px;
    // margin-right: 5px;
    // margin-top: 7px; 
    border-radius: 0px;
    width: 100%;
    text-align: left;
    display: flex;
}

.p-multiselect-label-container {
    // height: 30px;
}

.position-absolute {
    position: absolute;
}

.searchIconGlobal {
    position: absolute;
    top: 10px;
    float: right;
    opacity: 0.1;
    right: 20px;
}

.itemsWarperCard {
    position: absolute;
    width: 155px;
    // height: auto;
    height: 270px;
    overflow-y: auto;
    z-index: 9999;
    color: black;
    border: 1px solid #c8c8c8;
    background-color: #ffffff;
    // box-shadow: 0 3px 6px 0 rgb(0 0 0 / 16%);
}

.p-datatable-emptymessage {
    text-align: left !important;
    margin-top: 10px;
    color: red !important;
}

.itemsWarperGlobalSearchCard {
    position: absolute;
    width: 100%;
    // height: auto;
    height: 270px;
    overflow-y: auto;
    z-index: 9999;
    color: black;
    border: 1px solid #c8c8c8;
    background-color: #ffffff;
    // box-shadow: 0 3px 6px 0 rgb(0 0 0 / 16%);
}

.p-inputgroup {
    height: 37px !important;
}

.postionRelative {
    //top: 8px;
    position: relative;
}

.react-select__control {
    min-height: 32px !important;
    border-radius: 4px !important;
}

.react-select__value-container {
    padding: 0 8px !important;
}

.css-1g6gooi {
    margin: 0 !important;
    padding: 0 !important;
}

.cardBorderRadius {
    border-radius: 0.25rem;
}

.cardBorder {
    border: 1px solid lightgray;
}

.fontWeight {
    font-weight: bold;
    text-transform: capitalize;
}

.paddingRowDataCol {
    text-align: left;
    padding: 5px 5px;
}

.paddingOfRowData {
    // spadding: 5px 0;
    text-align: left;
}

.positionRelative {
    position: relative;
    top: 8px;
    font-family: "Inter", "sans-serif";
    font-weight: 800;
}

.chekcIconAdjust {
    font-size: 14px;
    margin-right: 4px;
    margin: 2px 4px 4px 2px;
}

.chekcIconAdjustLabel {
    margin-top: 4px;
}

.multiSelectWrapperItems {
    display: flex;
    padding-left: 5px;
    cursor: pointer;
}

.timeField {
    float: left;
    margin-right: auto;
    padding-left: 13px;
}

.multiSelectDropDownCard {
    height: 30px;
    width: 70px;
    cursor: pointer;
    font-size: 14px;
    color: #333333;
    background: #ffffff;
    padding: 0.529em 0.429em;
    border: 1px solid #a6a6a6;
    transition: border-color 0.2s,
        box-shadow 0.2s;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 3px;
    position: relative;
    top: 3px;
    text-transform: capitalize;
}

.multiSelectDropDownCardUl {
    padding: 5px;
    border: 0;
    border-bottom: 1px solid #0000001a;
}

.mobileScreenHaederView {
    position: relative;
    top: 8px;
}

.p-multiselect-panel .p-multiselect-item label {
    margin-bottom: 0 !important;
}

.p-multiselect-label {
    margin-bottom: 0 !important;
}

.p-autocomplete-input-token input {
    border: none
}

.logout_modal_width {
    width: 400px;
}

.notes_modal_width {
    width: 900px;
    min-height: 400px;
    overflow-y: auto;
}

.call_action_column {
    text-align: 'center';
    text-decoration: underline;
    color: $color-white;
    font-weight: bold;
    cursor: pointer;
}

.p-datatable-row {
    cursor: initial !important;
    color: black !important;
}

.p-datatable-row:hover {
    // background: transparent
}

.settings_form {
    width: 23%;
    padding: 5px 10px;
    font-size: 12px;
    height: 32px;
    transition: border 0.3s;
    background: transparent;
    border: solid 1px lightgray;
}

.form .settings_form:focus,
.form .settings_form:active {
    outline: none;
    border-color: #ffcd08;
}

.pending_badge,
.started_badge {
    padding: 5px;
    font-size: 14px;
    margin-bottom: 2px;
    padding-left: 7px;
    padding-right: 7px;
    color: white;
}

.vertical_middle {
    justify-content: center;
    align-items: center;
    display: flex;
}

.cardForGridMargin {
    margin: 7px 7px 0 7px !important;
}

.cardForListMargin {
    // margin: 18px !important;
    margin-bottom: 0 !important;
    width: 100%
}

.dropdownMinWidth {
    min-width: '10%' !important;
    line-height: 1.1px !important;
}

.dropdownMinWidthMarginTop {
    min-width: '10%' !important;
    line-height: 1.3px !important;
    margin-top: 2px !important;
}

.showHideModalClass {
    width: 400px !important;
    overflow-y: auto;
    overflow-x: hidden;
}

.widgetStatus {
    color: darkgray;
    padding: 10px;
    border-top: 1px solid lightgray;
    border-bottom: 1px solid lightgray;
    border-right: none;
    border-left: 1px solid lightgray;
    pointer-events: none;
}

// .widgetStatus:first-child{
//     border-left: 1px solid lightgray;
// }
.widgetStatus:last-child {
    border-right: 1px solid lightgray;
}

.widgetStatus:not(:first-child)::before {
    border-left: 1px solid lightgray;
}

// .widgetStatus:not(:first-child)::before{
//    content: " ";
//     display: block;
//     position: absolute;
//     top: -6px;
//     left: auto;
//     bottom: auto;
//     right: 73px;
//     border-top: 16px solid transparent;
//     border-bottom: 17px solid transparent;
//     border-right: none;
//     border-left: 11px solid lightgrey;
//     transition: border 0.2s ease 0s;
// }
// .widgetStatus:not(:first-child)::after{
//     content: " ";
//     display: block;
//     position: absolute;
//     top: -6px;
//     left: auto;
//     bottom: auto;
//     right: 146px;
//     border-top: 16px solid transparent;
//     border-bottom: 17px solid transparent;
//     border-right: none;
//     border-left: 11px solid lightgrey;
//     transition: border 0.2s ease 0s;
// }

.card-footer {
    background-color: transparent;
}

.four-sides-with-same-color {
    box-shadow: 0px 10px 10px 15px #f1f1f1;
}

.btn.btn-primary:before {
    background-color: $color-blue !important;
}

.loginhr {
    border: 0;
    clear: both;
    display: block;
    width: 6%;
    background-color: aliceblue;
    height: 1px;
}

// [data-rbd-droppable-context-id] {
//     overflow-anchor: none;
//     display: flex !important;
// }
.loginbtn {
    background-color: $color-blue !important;
}

.kanbanBtn {
    width: 20%;
}



a {
    display: inline-block;
    position: relative;
    line-height: 2.5;
    padding: 0 20px;
    color: #666 !important;
    text-decoration: none;
    z-index: 1;
}


li.active a {
    color: white !important
}

.kanbanColumn::-webkit-scrollbar {
    // display: none;
}

// /* Hide scrollbar for IE, Edge and Firefox */

.kanbanBtnThead {
    background-color: gray;

    th {
        color: white !important;
    }

}

.kanbanBtnTbody {
    background-color: #e7edf3;
}

.p-datatable-scrollable-body>table>.p-datatable-tbody>tr:first-child>td {
    // padding-top: 10px;
}


.filterButton {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none;
}


.tableheadericon {
    font-size: 20px;
}

.tableType {
    padding-left: 8px;
}

.modalFooter {
    background-color: #f2f4f7;
    padding: 10px 17px 10px 10px;
    position: relative;
    // position:absolute;
    // height: 50px;
    // background-color: red;
    bottom: 0px;
    left: 0px;
    right: 0px;
    margin-bottom: 0px;
}

.modalCardBody {
    padding: 10px;
    // min-height:calc(100vh - 150px)
}

.kanbanmodalCardBody {
    padding: 10px;
    min-height: calc(100vh - 117px) !important;
}


.squarebtn {
    margin-bottom: 5px !important;
    border-radius: 0px !important;
}

.p-datatable-thead {
    height: 37px;
}

.p-datatable-frozen-view th {
    padding: 27px 15px !important;
    //  padding: 38px 15px!important;
}

.sidebar__scroll>div {
    height: calc(110vh - 140px) !important;
}

.btn.btn-outline-primary:active {
    color: $color-white !important;
    background-color: $color-blue !important;
    border-color: $color-blue !important;
}

.btn.btn-outline-danger:active {
    color: $color-red !important;
}

.formbtn {
    height: 10px !important;
    margin-right: 0px !important;
}

.btnlabel {
    height: 37px !important;
    margin-top: 3px;
    margin-left: 5px;
}

.dashboard .dashboard__area {
    width: 97% !important
}

.dashboardcardTitle {
    margin: 5px;
    padding: 10px 20px;
}

.modal.fade .modal-header {
    padding: 20px 20px !important;
}

.modal__title {
    font-weight: bold !important;
    margin-top: 2px !important;
    font-size: 16px !important;
}

.modallnrIcon {
    font-size: 20px;
}

.modal__close-btn {
    margin-top: 7px;
}

.p-datatable-tbody {
    border: 1px solid lightgrey !important;
    border-top: none !important;
}

body .p-datatable .p-datatable-tbody>tr {
    border: 1px solid #e5e5e5 !important;
}

.customColorRows .p-datatable-frozen-view th {
    padding: 6px 15px !important;
}

.dropdown-item:hover,
.dropdown-item:focus {
    background-color: rgba(28, 160, 181, 0.29) !important;
}

.btn.btn-primary:hover svg {
    color: $color-white !important
}

.btn-group {
    border-radius: 4px !important;
}

.btn-group,
.btn-group-vertical {
    top: 6px !important;
    margin-right: 5px;
}

.btn.btn-sm {
    padding: 5px 18px !important;
    font-size: 14px;
}

.newCardButton {
    padding: 10px 20px 0px 20px;
    color: #475F7B !important;
    font-family: Inter;
    font-weight: 500;

}

.newCardBtn {
    margin: 5px;
}

button.mb-0.btn.btn-primary {
    padding: 5px !important;
    text-align: left !important;
}

.editor_header .p-accordion-header-text {
    width: 99%
}

.hideBorder {
    border-left: none !important;
}

.redirection_prop .modal-content {
    border: 2px solid black !important;
}

.dataSourceStyle {
    display: flex;
    align-items: center;
    padding-left: 0;

}

.dataSourcemain {
    border: 2px solid lightgray;
    display: flex;
}

.kanbanTitle {
    font-weight: normal;
    cursor: text;
    letter-spacing: 2px;
    // font-size: 1rem;
    color: #505050 !important;
    text-transform: capitalize;
}

.kanban_Card_title {
    color: #505050 !important;
    font-weight: 500 !important;
    font-family: Inter !important;
    padding: 5px 10px;
}

.tabs {
    overflow: hidden;
    background-color: white;

    .tabs__wrap {
        min-width: min-content;
        //-webkit-min-width: min-content;
    }

    .nav-tabs {
        flex-wrap: nowrap;

        li {

            .nav-link {
                padding: 10px 20px;
                margin-right: 0;
                // transition: background-color 0.3s;
                cursor: pointer;
            }
        }
    }

    &.tabs--bordered-top {
        .nav-tabs {
            li .nav-link {
                border-top: 2px solid transparent;
                border-radius: 0;
                cursor: pointer;


            }

            li .nav-link.active {

                &,
                &:focus,
                &:hover {
                    border-radius: 0;
                    border-top: 2px solid $color-accent !important;
                }
            }
        }
    }

    &.tabs--justify {

        .nav-tabs {
            display: flex;
            flex-wrap: nowrap;

            li {
                width: 100%;

                a {
                    text-align: center;
                }
            }
        }
    }

}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
    border: 1px solid #d6d7da !important;
}

.kanbanStatesbtn .btn.btn-sm {
    padding: 5px !important;
}

// .kanbanCardbody div{
// text-align:left!important;
// display:flex;
// // flex-direction: column;
// }
.kanbanCardbody .btn {
    padding: 0px 15px;
    margin: 0px;
    border-radius: 4px;
    position: relative;
}

.filterByField {
    display: contents;
}

.kanbanfilterDiv {
    border: 1px solid #f0f0f0;
    margin: 6px 9px 6px 12px;
    padding: 10px;
    box-shadow: 0px 1px 0px 0px #f1f1f1;
}

.filterKanbandiv {
    padding: 0px 10px;

    a {
        float: left;
        margin-left: auto;
        cursor: pointer;
    }
}

.btn.btn-primary:hover,
.btn.btn-primary:focus,
.btn.btn-primary:active,
.btn.btn-primary:active:focus {
    background-color: $color-blue !important;
    border-color: $color-blue !important;
}

.kanbanformfilter {
    width: 100%
}

.sidebar.sidebar--collapse .sidebar__scroll {
    width: 0px
}

.appLogo {
    height: 25px;
    width: 25px;
    border-radius: 20px;
    margin-top: 0px;
    border: 1.5px solid black;
}

.settingsCard {
    background-color: white;
    width: 95%;
    margin: 3%;
}

.kanbanswitch {
    // width: 35px!important;
    height: 20px !important;
    background-color: transparent;
    border: 1px solid lightgrey !important;
    top: -8px !important;

    &::after {
        top: -1px !important
    }

}

.errorColor {
    color: red;
}

.centerAlign {
    display: flex;
    justify-content: center;
}

.onlineUrlField {
    height: 29px;
    /* justify-content: center; */
    display: flex;
    align-items: center;
}

.checkbox-btn__checkbox:checked+.checkbox-btn__checkbox-custom {

    svg {
        opacity: 1;
    }
}

.filterCustum .p-dropdown-filter-container {
    width: 100%
}

.p-boldrow {
    font-weight: bold;
}

.filterCustum .p-dropdown-filter-container {
    width: 100%
}

.p-boldrow {
    font-weight: bold;
}

.p-isHighlightedDuplicateRow {
    background-color: #faf8be !important;
    ;
}

.p-boldrow {
    font-weight: bold;
}

.p-isHighlightedDuplicateRow {
    background-color: #faf8be !important;
    ;
}

.rangebuttontextstyle {
    color: white !important;
    // background-color: $color-blue;
}

.date-filter {
    font-size: 14px;
    margin-top: 7px;
    margin-bottom: 5px;
    width: 100%;
    max-width: 163px;
    background-color: $color-template;
    text-align: center;
    color: white !important;
    padding: 6px 3px;
    font-weight: 700;
    border-radius: 20px;
}

.sidebar__category-wrap--open .sidebar__link {
    margin-bottom: 0px;
}

.sidebar__submenu {
    padding: 7px 0px;

}

.sidebar__submenu a {
    margin-bottom: 4px;

}

.stv-radio-buttons-wrapper {
    clear: both;
    display: inline-block;
    //newly
    //    border:1px solid black;

}

.circle {
    height: 30px;
    width: 30px;
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    border-radius: 50%;

}

.stv-radio-button {
    position: absolute;
    left: -9999em;

    &+label {
        float: left;
        padding: 5px 10px;
        cursor: pointer;
        border: 1px solid lightgrey;
        margin-right: -1px;
        color: rgba(12, 11, 11, 0.74);
        background-color: #fefeff;


    }

    &:checked+label {
        background-color: #3277b3;
        color: white;
    }
}

span.theme-white.radio-btn__radio-custom.permissions {
    width: 45px !important;
    padding: 13px 15px;
    border-radius: 0px !important;
}

label.radio-btn.radio-btn--permissions {
    padding-left: 0px !important;
}

.form__form-group-field.permissions {
    margin-left: 0px !important;
    padding-left: 0px !important;
}

.form__form-group .permissions {
    width: 88% !important;
}

span.theme-white.radio-btn__radio-custom.permissions span {
    top: -20px;
    position: relative;
}

.dashboardCard {
    background-color: $color-white;
    padding: 10px;
    // height: 100%;
}

.modal__header.kanbanScreen.modal-header {
    padding: 17px 20px 15px 17px !important;
}

.react-autocomplete-input {
    height: 135px;
    overflow-y: scroll;
}

.css-bg1rzq-control.react-select__control {
    border-color: #cfd0d2 !important;
}

.buttonSeperator.applyButton {
    background-color: $color-blue !important;
}

body .p-multiselect-panel .p-multiselect-items .p-multiselect-item label {
    font-size: 13px !important;
}

.categoryTitle {
    left: 48px !important;
}

label.p-multiselect-label {
    font-size: 12px !important;
    text-align: left !important;
}

.body .p-multiselect .p-multiselect-label {
    font-size: 13px !important;
}

.dateFilterForm {
    background-color: #ffffff !important;
}

.form.form--horizontal .form__form-group-label {
    font-size: 13px !important;
}

.Card_container {
    max-width: calc(100vw - 100px) !important;
    margin-left: 0px;
}

//kanban_ Screen Css
.kanban_drag {
    display: inline-grid;
    width: 100%;
    height: calc(100vh - 266px);
    overflow: hidden;
    //  height:50vh
}

.kanban_drag_column {
    overflow-x: scroll;
    width: 99%;
    height: 100%;
    display: flex;
    padding: 0px;
    margin: 0px;
    overflow-y: hidden;
}

.kanbanColumn {
    border: 1px solid lightgray;
    background-color: white;
    border-radius: 0 !important;
    height: inherit;
    margin: 5px;
    padding: 15px 0px 15px 10px;
    // min-width: 25%;
    height: calc(100vh - 315px);
    // overflow-y:scroll ;
}

.kanbancardimg {
    width: 30px;
    height: 30px;
    margin-top: 2px;
    border-radius: 50%;
    margin: 5px;
    border: 1px solid #505050
}

.kanbanCard {
    padding: 0px;
}

.kanbanCardbody {
    padding: 10px;

}

.kanbanCardfooter .progress {
    height: 5px;
    margin-bottom: 5px;
}

.kanban_filter_label {
    margin-bottom: 1px;
    text-align: left;
    padding: 0px;
    font-size: 14px;

}

.kanban_filter_field {
    padding-left: 10px;
    padding-right: 5px;
    width: 25%;
}

.kanban_title {
    // font-size: 14px;
    text-transform: capitalize;
    color: #334152;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    //   line-height: 1.2; 
    //white-space: nowrap;
}

.removeCapitalize {
    text-transform: none;

}

.kanbanCard {
    box-shadow: -4px 4px 6px 0 transparent;
    position: relative;
    background: white;
    //margin:10px;
    // height:150px;
    font-family: Inter;
    font-size: 15px;
    font-weight: 400;
    color: #505050;
}

.sub_text {
    width: 100%;
    text-align: left;
    font-family: 'Inter,Verdana, Geneva, sans-serif';
    font-size: 13px;
}

.sub_text.mainTitle {
    text-align: center;
    padding-bottom: 10;
}

.Kanban_title_card {
    padding-left: 13px;
    padding-bottom: 13px;
    color: $color-blue !important;
    font-weight: 700;
    cursor: pointer
}

.errorResponse {
    padding-left: 13px;
    padding-bottom: 6px;
    // color: #007ad9!important;
    // font-weight: 700; 
    cursor: pointer font-size 10px !important;
}

.formError {
    bottom: 17px;
    position: absolute;
    justify-content: center;

}

.datatable_status_field {
    margin-right: 8px;
    width: 85%;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    // overflow: hidden;
    white-space: nowrap;
}

.modal.fade .modal__body {
    padding: 15px 10px 10px 10px;
    min-height: calc(100vh - 124px);
}


.leftMargin {
    margin-left: 10px;
}

.status_logo {
    width: 9px !important;
    margin-right: 5px;
}

.hide {
    display: none !important;
}

.edit_modal {
    padding-left: 30px !important;
    padding-right: 40px !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}

.daterangepicker:before,
.daterangepicker:after {
    right: 10px !important;
}

.sub_text .sub_title {
    font-weight: bold;
    font-size: 13px;
    color: #505050 !important;
}

.Custom_container {
    width: 70%;
    margin-left: 0;
}

.keylist {
    border: 1px solid #c8c8c8;
    padding: 10px 0px;
    margin-top: 11px;
}

.kanbanCardcolumn,
.col_Order {
    height: calc(100vh - 400px);
    overflow-y: scroll;

    //   -ms-overflow-style: none;  /* IE and Edge */
    //   scrollbar-width: none;
    &::-webkit-scrollbar {
        width: 10px;
        height: calc(100vh - 40px);
        margin-left: -10px;
        -webkit-appearance: none;
    }

    &::-webkit-scrollbar-track {
        background-color: white;
        border-right: 1px solid #f2f2f2;
        border-left: 1px solid #f2f2f2;
    }

    &::-webkit-scrollbar-thumb {
        height: 50px;
        background-color: #e6e6e6;
        background-clip: content-box;
        border-color: transparent;
        border-style: solid;
        border-width: 1px 2px;
    }

    /* Firefox */
}

.popoverCard {
    width: 250px;
    min-height: 250px;
    border-radius: 0 !important;
}

.kanbanCheckbox {
    height: 14px !important;
    width: 25px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
}

.checkbox-btn.checkbox-btn--kanbanCheckbox {
    margin-bottom: 12px !important;
    margin-left: auto;
    margin-top: 0px !important;
}

.kanbanprogress .btnlabel {
    height: 10px !important;
}

.kanban_switch {
    width: 30px;
    padding: 5px 0px !important;
    float: right;
}

.kanban_switch .toggle-btn__input {
    height: 10px;
}

.kanban_switch .toggle-btn__input-label {
    width: 30px;
    height: 15px;
    background-color: grey;
}

.kanban_switch .toggle-btn__input-label::after {
    width: 15px;
    height: 15px;
}

.kanban_Switch {
    width: 61px !important;
    margin-top: 11px;

}

.colWidth {
    max-width: 25%
}

.alignCenter {
    align-items: center;
    display: flex;
}

.kanbanprogress {
    display: flex
}

//  @media screen and (width:2100px){
//    .daterangepicker{
//     left:-100%!important;
//  }
//  }
// @media screen and (width:1500px){
//    .daterangepicker{
//     left:-420px!important;
// }
// }
// @media screen and (width:1024px){
//    .daterangepicker{
//     left:-574px!important;
// }
// }
// @media screen and (width:768px){
//    .daterangepicker{
//     left:-512px!important;
// }
// }
// @media screen and (width:1300px){
//    .daterangepicker{
//     left:-470px!important;
// }
// }
@media screen and (max-width:1024px) {
    .showHideModalClass {
        width: auto !important;
        height: 200px !important;
        overflow-y: auto;
        overflow-x: hidden;
    }
}

@media screen and (min-width:2000px) {
    .modal.fade .modal__body {
        min-height: calc(100vh - 128px)
    }
}

@media screen and (min-width:3000px) {
    .modal.fade .modal__body {
        min-height: calc(100vh - 130px)
    }
}

@media screen and (min-width: 576px) {
    .sidebar--collapse .categoryTitle {
        left: 58px !important;
    }
}

@media screen and (min-width:1700px) {
    .kanbanmodalCardBody {
        min-height: calc(100vh - 119px) !important;
    }
}

@media screen and (min-width:2500px) {
    .kanbanmodalCardBody {
        min-height: calc(100vh - 121px) !important;
    }
}

@media screen and (min-width:3000px) {
    .kanbanmodalCardBody {
        min-height: calc(100vh - 129px) !important;
    }
}

.configureColor {
    font-weight: 600;
    color: #1176ee;
    width: max-content;
}

.container__wrap {
    padding-top: 60px;

    @media screen and (min-width: 576px) {
        padding-left: 212px;
    }
}

// .colWidth{
//     max-width: 25%!important;
// }
@media screen and (width: 1024px),
screen and (height: 1024px) {
    .colWidth {
        max-width: 50% !important;
    }
}

@media screen and (width: 768px) {
    .Custom_container {
        width: 100%;
    }
}

// @media screen and (max-width:1300px){
//    .daterangepicker{
//     left:-450px!important;
// }
// }
// @media screen and (min-width: 1310px) and (max-width: 1600px){
//    .daterangepicker{
//     left:-413px!important;
// }
// }
// @media screen and (width:1625px){
//    .daterangepicker{
//     left:-372px!important;
// }
// }
// @media screen and (min-width: 1625px) and (max-width: 1740px){
//    .daterangepicker{
//     left:-345px!important;
// }
// }
// @media screen and (width:1920px){
//    .daterangepicker{
//     left:-290px!important;
// }
// }
// @media screen and (width:1950px){
//    .daterangepicker{
//     left:-290px!important;
// }
// }
// @media screen and (width:2600px){
//    .daterangepicker{
//     left:-123px!important;
// }
// }
// @media screen and (width:1024px){
//    .daterangepicker{
//     left:-522px!important;
// }
// }
// @media screen and (width:768px){
//    .daterangepicker{
//     left:-577px!important;
// }
// }
.active .sidebar__link p,
.active .sidebar__link-icon {
    color: $color-black !important;
}

button.sidebar__link.sidebar__category {
    &:hover {

        .sidebar__link-icon,
        p,
        .sidebar__category-icon {
            color: $color-black !important;

        }
    }
}

body .customSwitch .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
    background-color: green;
}

body .customSwitch .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:hover {
    background-color: green !important;
}

body .customSwitch .p-inputswitch-slider {
    background-color: red;
    /* Background color when off */
}

body .customSwitch .p-inputswitch-slider:hover {
    background-color: red !important;
    /* Background color when off */
}

.button_season .active {
    background-color: $color-blue !important;
}

.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show>.btn-outline-primary.dropdown-toggle {
    background-color: $color-blue !important;
    border-color: $color-blue !important;
}

.notesdate_font {
    font-size: 11px;
}

.notes_details {
    line-height: 1.3 !important;
    padding-bottom: 2;
    //   minWidth: 65 ;
}

.btnRadius {
    border-radius: 50%;
    padding: 5px;
}

.p-input-icon-left,
.p-input-icon-right {
    position: relative;
    display: inline-block;
}

.p-input-icon-right>i:last-of-type,
.p-input-icon-right>svg:last-of-type,
.p-input-icon-right>.p-input-suffix {
    right: 0.75rem;
    color: red;
}

.p-input-icon-left>.p-input-prefix,
.p-input-icon-left>i,
.p-input-icon-left>svg,
.p-input-icon-right>.p-input-suffix,
.p-input-icon-right>i,
.p-input-icon-right>svg {
    position: absolute;
    top: 50%;
    margin-top: -.5rem;
}

.p-input-icon-left>i:first-of-type,
.p-input-icon-left>svg:first-of-type,
.p-input-icon-left>.p-input-prefix {
    left: 0.75rem;
    color: #6c757d;
    z-index: 1;
    top: 20px
}

// .pi-chevron-right:before {
//     content: "\002B";
// }

// .p-row-toggler .pi-chevron-down:before {
//     content: "\2212";
// }

body .p-datatable .p-datatable-tbody>tr>td .p-row-toggler {
    font-size: 1em;
}

.checklist_data {
    height: 200px;
    display: block;
    overflow-y: auto;
}

.check_list {
    display: none;
}

.checklist_data .checkbox-btn {
    margin-top: 5px;
    margin-bottom: 5px;
}

.p-autocomplete .p-button.p-button-icon-only .p-button-text {
    padding: 7px;
}

.p-autocomplete .p-button {
    background-color: #ffffff;
    border-color: #dddddd;
    color: #dddddd
}

.p-autocomplete .p-button {
    background-color: #ffffff;
    border-color: #dddddd;
}

.p-autocomplete .p-button:hover {
    background-color: #ffffff !important;
    border-color: #dddddd !important;
    color: #dddddd !important
}

.child_Companie .p-datatable-thead {
    display: none;
}

.child_Companie .p-datatable .p-datatable-tbody>tr>td {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-align: left;
}

.child_Companie .p-datatable-scrollable-body {
    overflow-x: hidden;
}

.child_Companie .p-datatable .p-datatable-tbody>tr {
    background-color: #E6F0F5;
}

.child_Companie .p-datatable .p-datatable-tbody>tr:nth-child(even) {
    background-color: #E6F0F5;

}

.modal__identifier-btn {
    position: absolute;
    font-size: 14px;
    font-weight: 700;
    background: transparent;
}

.modal ul,
.tableCardBody ul {
    border-bottom: 2px solid #dee2e6 !important;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
    border-bottom: 2px solid #354f6e !important;
    border: none !important;
    cursor: pointer;
}

// .claimFields .form__form-group-input-wrap {
//     width: 27%;
//     margin-left: 58px;
// }

.claimFields1 .form__form-group-input-wrap {
    width: 27%;
    margin-left: 27px;
}

.claimFields2 .form__form-group-input-wrap {
    width: 24%;
    margin-left: 7px;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
    border-bottom: 3px solid #354f6e !important;
    font-weight: bold;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
}

.colspan {
    padding: 0px;
}

.p-row-toggler-icon.pi.pi-fw.p-clickable.pi-chevron-right span,
span.p-row-toggler-icon.pi.pi-fw.p-clickable.pi-chevron-down span {
    font-size: 14px !important
}

.p-row-toggler-icon.pi.pi-fw.p-clickable.pi-chevron-right span,
span.p-row-toggler-icon.pi.pi-fw.p-clickable.pi-chevron-down span {
    font-size: 14px !important
}

.MuiSwitch-switchBase {
    top: 10px !important;
    left: -2px !important;
}

.claimFields {
    margin-bottom: -5px;
}

.MuiSwitch-thumb {
    color: white !important
}

.MuiSwitch-colorSecondary.Mui-checked+.MuiSwitch-track {
    background-color: green !important;
}

.MuiSwitch-track {
    height: 110% !important;
}

.splitTasks thead {
    background-color: #354f6e;
    color: white;
}

.splitTasks tr:nth-child(even) {
    background-color: #d8dce0;
}

.nav-tabs .nav-link {
    border: none !important;
}

body .p-dropdown {
    border: solid 1px lightgray
}

body .p-dropdown .p-dropdown-label {
    padding-top: 3px !important;
    padding-bottom: 0px !important;
}

.edit_modal .formError {
    text-align: center !important;
    width: 90%;
}

.subsidary ul {
    border-bottom: none !important;
}

.split_label thead {

    position: fixed;
    top: 0px;
    display: none;
    // background-color:white;
}

.filterField {
    padding-right: 10px !important;
}

.split label {
    display: flex;
    //   padding-left: 15px;
    //   text-indent: -15px;
}

.split input {
    width: 13px;
    height: 13px;
    padding: 2px;
    margin-top: 4px;
    margin-right: 4px;
    vertical-align: bottom;
    position: relative;
    top: -1px;
    *overflow: hidden;
}

.globalSearchField {
    display: flex;
    width: 100%;
    border: 1px solid lightgray;
}


.showAssignedError {
    bottom: 0 !important;
    ;
    position: absolute !important;
    ;
}

.stickyHeader .p-datatable-thead {
    position: sticky
}

// .stickyHeader .p-datatable .p-datatable-tbody > tr{
//     height:90px!important;
// }
.stickyHeader .p-datatable .p-datatable-tbody>tr {
    border-bottom-color: $color-blue !important;

}

.stickyHeader .p-datatable .p-datatable-tbody>tr>td {
    border: 1px solid #c8c8c8 !important
}

// .stickyHeader .p-datatable .p-datatable-tbody > tr:nth-child(even){ background-color: #e6f0f5!important;}

.p-datatable-tbody {
    background-color: #8cbfe3 !important;
}

[data-letters]:before {
    content: attr(data-letters);
    display: inline-block;
    font-size: 1em;
    width: 2.5em;
    height: 2.5em;
    line-height: 2.5em;
    text-align: center;
    border-radius: 50%;
    background: #354f6e !important;
    vertical-align: middle;
    //   margin-right:1em;
    color: white;
}

.recentTask-topbar {
    padding: 0px 10px;
    border-left: 4px solid #354f6e;
    border-right: 4px solid #354f6e;
}

.progress-loader {
    //   top:10px;
    //   left:48%;
    position: absolute;
    height: 10px;
    width: 84%;
    z-index: 1000;
}

.progress-loader_match {
    //   top:10px;
    //   left:48%;
    position: absolute;
    height: 10px;
    width: 100%;
    z-index: 1000;
}

.bar_container {
    background-color: #ffffff;
    top: 12px;
}

.barCompleted {
    height: 10px;
    background: rgb(106, 27, 154);
    transition: width 1s ease-in-out 0s;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
}

.pageFade {
    transition: opacity 0.15s linear;
    opacity: 0.5
}

.progress-bar-animated {
    background-color: #3086dd !important
}

.screen-loader {
    position: absolute;
    width: 97%;
    height: 10px;
    z-index: 1000;
    top: 2px;
}

.btn.btn-sm .form_exportbtn {
    padding: 6px 18px !important;
}

body .p-checkbox .p-checkbox-box {
    width: 17px;
    height: 17px;
}

// .p-datatable .p-datatable-tbody>tr>td.p-cell-editing {
//     padding-left:0px!important;
// }
.p-datatable .p-datatable-tbody>tr>td.p-cell-editing .p-component.p-autocomplete-dropdown {
    width: 22px;
    border-color: #b1abab;
}

td.p-cell-editing .p-dropdown.p-component {
    min-width: 80% !important;
}

td .p-autocomplete .p-button.p-button-icon-only .p-button-text {
    padding: 0.429em;
}

td input.p-inputtext.p-component.p-autocomplete-input,
input.p-inputtext.p-component.p-autocomplete-input {
    border: solid 1px lightgray;
    border-right: none;
}

.topbar__center input.p-inputtext.p-component.p-autocomplete-input {
    height: 35px;
}

body td .p-row-editor-save {
    display: none !important;
}

// body td .p-row-editor-cancel{
//      display: none!important;
// }
.p-editable-column .p-cell-editing {
    height: 45 !important;
}

.nav-link.disabled {
    color: grey !important
}

.theme-light .react-select__dropdown-indicator,
.theme-light .react-select__clear-indicator {
    color: grey !important
}

.tableCardBody input.p-inputtext.p-component.p-autocomplete-input.p-autocomplete-dd-input {
    width: 85% !important;
}

.claimFilterForm input.p-inputtext.p-component.p-autocomplete-input.p-autocomplete-dd-input {
    width: calc(100% - 2.357em) !important;
}

// .claimTab .active {
//     color: black !important
// }

.claimTab .nav-link {

    color: white !important;

    &.active {
        color: #354f6e !important
    }
}

.viewLoader {
    top: 64px;
    position: absolute;
    width: 97%;
    height: 10px;
    z-index: 1000;
}

.modal.fade .modal-dialog .p-fileupload-row {
    display: grid;
    max-width: 307px;
    overflow-x: auto;
}

.getFilesContainer {
    border: 1px solid lightgray;
    margin: 15px 0px;
}

// body .modal .modal-dialog .p-fileupload .p-fileupload-content{
//     height: 150px;
//     overflow: auto;
// }
body .p-accordion .p-accordion-header:not(.p-disabled).p-highlight a {}

body .p-accordion .p-accordion-header a {
    background-color: white !important;
    padding: 0px !important;
}

body .p-accordion .p-accordion-header:not(.p-highlight):not(.p-disabled):hover a {
    background-color: white !important;
    padding: 0px !important;
}

body .p-accordion .p-accordion-header:not(.p-disabled).p-highlight:hover a .p-accordion-toggle-icon {
    color: #333333
}

.p-accordion .p-accordion-header a {
    padding: 2px
}

.topbar__info {
    color: #354f6e !important;
    font-weight: 600;
}

.shareIcon {
    margin: auto 5px;
    font-size: 35px;
    color: #354f6e;
    border: none !important;

}



.expandIcon {
    margin: auto;
}

.p-disabledRow .p-checkbox {
    // pointer-events: none;
    display: none;
}



.p-removeSelection {
    pointer-events: none;
    display: none;
}

.disabledSelection th .p-checkbox-box.p-component {
    display: none !important;
    pointer-events: none !important;
}

.disabledSelection th.p-resizable-column.p-selection-column {
    pointer-events: none !important;
}

.hideheader {
    .p-multiselect-header {
        display: none;
    }
}

.property_Summary {
    .react-datepicker__input-container {
        input {
            width: 360px !important;
        }
    }
}

th label.p-dropdown-label.p-inputtext.p-dropdown-label-empty {
    display: contents;
}

.p-sortable-column-icon {
    .p-dropdown-trigger {
        width: 2px !important;
        background-color: inherit !important;
    }
}

.p-sortable-column-icon {
    .pi-chevron-down:before {
        content: "\e950";
        // content: "\2212";

    }
}

.p-customFilter_header {
    display: flex;
    justify-content: space-between;
    width: 100%;

}

.pi-sort:before {
    content: "" !important;
}

.p-calendar input.p-inputtext.p-component.p-inputtext.p-component {
    width: 100%
}

.modalTablePadding {
    padding: 0px
}

body .p-selectbutton .p-button {
    background-color: white;
    border: 1px solid #dadada;
}

body .p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover {
    color: white
}

// body .p-datatable .p-datatable-tbody tr:nth-child(even).p-datatable-row.p-highlightRow{
//         background-color: white !important;
//     // color: #ffffff;
// }

body .p-selectbutton .p-button.p-highlight {
    background-color: $color-accent;
}

.match_property_modal {
    max-width: 1000px !important;
}

.up_filing .p-dropdown {
    min-width: 20% !important;
}

.configuration {

    top: -15px;
    //   left:48%;
    position: absolute;
    height: 10px;
    width: 84%;
    z-index: 1000;

}

.modal-dialog.right.task {
    width: 500px !important;
}

.p-datatable-frozen-view th {
    padding: 6px 15px !important;
}

tr.p-datatable-row.p-highlight.p-highlightRow {
    background-color: #007ad9 !important;
    color: #ffffff !important;
    ;
}

body .p-datatable .p-datatable-tbody tr.p-datatable-row.p-highlightRowRed {
    background-color: #e39e9e !important;
    ;
}

body .p-datatable .p-datatable-tbody tr.p-datatable-row .p-highlight .p-highlighteddarkRow {
    background-color: #007ad9 !important;
    color: #ffffff !important;
    ;

}

.rule_screen_title {
    color: #354f6e !important;
    font-weight: 600;
    justify-content: center;
    margin: 10px 5px;
}

.rules_details {
    border: 2px solid lightgray;
}

.rule_title_field {
    width: 15%,

}

.stepNo {
    padding: 10px 15px;
    border: 2px solid lightgrey;
    margin: 0px 5px;
}

.step_if {}

.react-datepicker__day-name {
    color: #354f6e !important
}

@media screen and (min-width: 576px) {
    .sidebar.sidebar--collapse {
        width: 0px !important;
    }
}

@media screen and (min-width: 576px) {
    .layout.layout--collapse+.container__wrap {
        padding-left: 10px !important;
        padding-right: 10px !important;
    }
}

@media screen and (min-width: 576px) {
    .sidebar--collapse .sidebar__wrapper--desktop {
        display: none;
    }
}

.theme-light .topbar__link:hover {
    background-color: #ffffff;

}

.topbar__link:before {
    width: 0px;
}

.import_upload {
    height: 30px;

}

.exported-card {
    width: 98%;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 1%;
}

.p-progressbar-label {
    font-size: 12px;
    bottom: 4px;
    color: #ffffff !important;
}

body .p-input-overlay-visible {
    min-width: 350px !important
}

.claim_detail_default_button {
    color: var(--lara-global-text-color, #495057);
    font-family: Inter;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    padding: 7px;
    // margin-right: 5px;
}

.claim_detail_sub_navbar_buttons {
    color: var(--lara-global-text-color, #495057);
    font-family: Inter;
    font-style: normal;
    padding: 2px;
    margin-right: 6px;
    margin-top: 7px;
    border-radius: 6px;
    font-size: 12px;
    font-weight: 600;
    line-height: 17px;
}

.claim_detail_default_text {
    color: var(--lara-global-text-color, #495057);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
}

.paymentLabelText {
    color: var(--lara-global-text-color, #495057);
    font-family: Inter;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    // line-height: 16.8px;
}

.claim_detail_default_fieldInput {
    border-radius: 4.8px;
    border: 0.8px solid var(--lara-input-field-input-border, #CED4DA);
    background: var(--lara-input-field-input-bg, #FFF);
    width: 100%;
}

.claim_detail_value_ranges_text {
    color: var(--lara-global-text-color, #495057);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16.8px;
    /* 186.667% */
}

body .p-input-overlay-visible {
    min-width: 350px !important
}

.modal-dialog .claim_creation_modal {
    max-width: 1200px !important;
}

.claim_creation_modal {
    max-width: 1200px !important;
}

.rnd_Modal .claim_creation_modal {
    min-width: 450px !important;
    min-height: 300px !important;
    max-width: none !important;

    .card-body {
        overflow: auto !important;
    }

    th {
        color: white !important;
    }
}


.claim_creation_modal .modal-content,
.custumWidth .modal-content {
    padding: 0 !important;

}

.fullHeader .modal-content,
.custumWidth .modal-content {
    padding: 0 !important;

}

.bg-primary,
a.bg-primary:focus,
a.bg-primary:hover,
button.bg-primary:focus,
button.bg-primary:hover {
    background-color: #354f6e !important;
}

.claim_creation_modal .modal__header,
.custumWidth .modal__header {
    background-color: #354f6e;
    color: white !important;
}

.Claims .p-datatable .p-datatable-tbody>tr.p-highlight {
    background-color: #acadb378 !important;
}

.date-picker .datepicker_is_edit_show,
.react-select-isedited .react-select__control,
.autocomplete_is_edit_show .p-button,
.autocomplete_is_edit_show .p-autocomplete-input {
    background: #f8ac8bab;
}

.react-isedited .p-dropdown .p-component,
.react-isedited .p-dropdown-label,
.react-isedited .p-dropdown-trigger {
    background-color: #f8ac8bab !important;
}


body .p-datatable .p-datatable-tbody tr.p-datatable-row.p-greenhighlightedRow {
    background-color: #E2EFDA !important;
    // color: #ffffff;
}

body .p-datatable .p-datatable-tbody tr.p-datatable-row.p-greenFollowhighlightedRow {
    background-color: #9aed67 !important;
    // color: #ffffff;
}

body .p-datatable .p-datatable-tbody tr.p-datatable-row.p-lightBrownhighlightedRow {
    background-color: #FFF2CC !important;
    // color: #ffffff;
}

body .p-datatable .p-datatable-tbody tr.p-datatable-row.p-yellowhighlightedRow {
    background-color: #FFFFCC !important;
    // color: #ffffff;
}

body .p-datatable .p-datatable-tbody tr.p-datatable-row.p-redhighlightedRow {
    background-color: #FBE3D6 !important;
    // color: #ffffff;
}

body .p-datatable .p-datatable-tbody tr.p-datatable-row.p-greenFollowhighlightedRowHistory {
    background-color: #C2F1C8 !important;
    // color: #ffffff;
}

body .p-datatable .p-datatable-tbody tr.p-datatable-row.p-brownhighlightedRow {
    background-color: #FFC7CE !important;
    // color: #ffffff;
}

.custom-progress-bar {
    .p-progressbar .p-progressbar-value {
        background: #4cc0ea !important;
    }

    .p-progressbar {
        border-radius: 6px !important;
    }

    .p-progressbar-label {
        color: #333333 !important;
    }
}

body .p-dropdown .p-dropdown-panel {
    max-width: 100%;
}

#document-upload-modal {
    max-width: unset !important;

    .p-input-overlay-visible {
        min-width: 100% !important;
    }

    .p-multiselect .p-multiselect-panel {
        max-width: 100% !important;

        label {
            padding-right: 5px;
        }
    }
}

body .p-menubar {
    border: none !important;
}

// .topbar__profile .p-menubar .p-submenu-list>.p-menuitem-active>.p-submenu-list{
//     right: 100% !important;
//     left: -100% !important;
// }
.dm-action-buttons {
    font-size: small;

    .btn-sm,
    button.mb-0.btn.btn-primary {
        padding: 5px 10px !important;
        border-radius: 6px;
        font-size: smaller;
    }

    .btn-primary {
        background-color: #64748B !important;
        border-color: #64748B !important;
    }

    .btn-danger {
        background-color: #EF4444 !important;
    }
}

.dm-search-btn button.mb-0.btn.btn-primary {
    padding: 5px 18px !important;
}

.dm-search-btn button.mb-0.btn.btn-primary.cf-btn {
    padding: 5px 28px !important;
}

.doc-maintenance {
    td {
        text-wrap: pretty !important;
    }

    .paginationcss {
        .p-dropdown .p-dropdown-panel {
            min-width: min-content !important;
        }
    }

}

.p-overlaypanel:after,
.p-overlaypanel:before {
    right: 1.25em;
    left: initial
}

;

.overlayPanelForReport {
    border-color: "black";
    margin-right: "0px";
    margin-bottom: "0px";
    width: "3%";
}

.overlayPanelForReportButton {
    margin-bottom: "0px";
    width: "24%";
    height: "36px";
    margin-top: "14px";
}


.statusReason {
    margin-bottom: 4;
    width: "100%";
    display: "flex";
}

.sellerCNNamesDropDown {
    .p-input-overlay-visible {
        min-width: max-content !important;
    }
}

.date-group {
    .form-group {
        flex-grow: 1;
    }

    .p-dropdown {
        min-width: unset;
    }
}

.session .modal-content {

    border-style: solid
}

.cke_contents {
    height: calc(100vh - 270px) !important;
}

.normalDiv {
    margin-left: 15px !important;
}

.selectedField {
    font-size: 14px;
    font-weight: bold;
    padding-left: 5px;
    margin-left: 20px;
    height: 25px;
}

.recentTask-topbar .p-inputswitch.p-inputswitch-checked span.p-inputswitch-slider {
    background-color: green
}

.modalContent .modal-content {
    padding: 0px !important;

    .modal-header {
        background-color: #354f6e;
        color: white;
    }
}

.reset_Filters {
    display: flex;
    align-items: center;

    .clear_icon {
        width: 16;
        height: 16;

    }
}

.connectedClaim {
    height: 400px;
    // text-align: center;
    // /* font-size: 17px; */
    // font-weight: bold;
    // padding: auto;
    margin-left: 10px;

}

.info_form {
    z-index: 1067 !important;
}

.disabled-link {
    pointer-events: none;
    color: black !important;
}

.taskIDclaims {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    padding-left: 30px;
    // font-size: 15px;
}



.claim_rfi_add {
    display: flex;
    justify-content: flex-end;
}

.successMessage {
    color: green,
}

.current_preset {
    font-size: small;
    font-weight: 700;
}

.scroll_filesContainer {
    max-height: 150px;
    overflow: auto;
}

.fileNamestyled {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.exclusionMess {
    display: inline-block;
    width: 820px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
}

.text-ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.disableClick {
    pointer-events: none;
    cursor: default;
    opacity: 0.5;
}

.disableVisual {
    filter: opacity(0.8);
}

.disabledRow {
    td {
        background-color: #d3d3d352;
    }
}

.claims-manager {
    .paginationcss {
        .p-dropdown .p-dropdown-panel {
            min-width: min-content !important;
        }
    }
}


.modal-content label.p-dropdown-label.p-inputtext {
    text-align: justify;
}

.manual_match {
    margin-left: auto;
    margin-right: auto;
}

.info-color {
    color: #354f6e !important;
}

.dataSource_multiDiv {
    .p-input-overlay-visible {
        min-width: 170px !important;
    }
}

td:has(.errordatatablebgcolr),
.errordatatablebgcolr {
    background-color: #fcd3D2 !important;
}
.warningdatatablebgcolr {
    background-color: #f39c11 !important;
}
.warningdatatabletdcolr {
    color: #e8d1b7 !important;
}


.errordatatabletextcolr {
    color: #e84c3d;
}

.warningdatatabletextcolr {
color: #f39c11 !important;
}

.table.table-bordered-header {
    tbody {
        tr {border: 1px solid #dee2e6 !important;}
        td{color:black}
    }
    thead {
        th {
            border: 1px solid #dee2e6 !important;
            color:black
        }
    }

}


.editor_header .p-accordion .p-accordion-header a {
    border: none !important;

    .preventParentInherit {
        border: 1px solid lightgray !important;
        font-weight: initial !important;
        // padding:  1px !important;
        height: 32px !important;

    }
}

.editor_header .p-accordion-header:not(.p-disabled) a:focus {
    box-shadow: none !important;
}


.accordian_custum_header {
    height: 70px;
    align-items: center;
    display: flex;
    border-style: solid;
    border-width: 1px 1px 1px 1px;
}

.accordian_custum_header a {
    border-width: 0px 1px 0px 1px;
}

.custum_btns_for_PE {
    border: 1px solid #c8c8c8 !important;
    padding-right: 5px;
}

.accordian_custum_header {
    border-color: #c8c8c8 !important;
    position: relative;
    // border-left: none!important;
}

.editor_header .p-accordion .p-accordion-header a:first-child {
    border-left: 1px solid #c8c8c8 !important;
    position: relative;
}

.accordian_custum_header:after {
    padding: 0;
    margin: 0;
    display: block;
    /* probably not really needed? */
    content: "";
    // width:50%;
    height: 1.1px;
    background-color: white;
    position: absolute;
    right: 0;
    top: -1px;
}

.editor_header .p-accordion .p-accordion-header a .p-accordion-toggle-icon {
    padding: 27px 0px;
    border-width: 1px 0px;
    border-color: #c8c8c8;
    border-style: solid;
}

td:has(.errordatatablebgcolr) {
    background-color: #d7acac !important;
}

.rnd_modal {
    z-index: 999;
}

.applyFilterBtn {
    display: flex;
    align-content: center;
    flex-wrap: wrap;
    justify-content: center;
}

.multisortModal {
    table {
        width: 60%;
        border-collapse: collapse;
    }

    th,
    td {
        border: 1px solid #ddd;
        padding: 8px;
    }

    tr.selected {
        background-color: #f2f2f2 !important;
    }

    tr.notSelected {
        background-color: #ffffff;
    }
}

.multiRepFile {
    color: blue;
    text-decoration: underline;
    font-weight: bold;
}

.cursor-pointer {
    cursor: pointer;
}

.go-button {
    background-color: #4ea250 !important;
    border-color: #4ea250 !important;
    color: white !important;

    &:hover {
        background-color: #4ea250 !important;
        border-color: #4ea250 !important;
    }
}

.document-library {
    .paginationcss {
        .p-dropdown .p-dropdown-panel {
            min-width: min-content !important;
        }
    }
}

.successbgColor {
    background-color: #e6eed1;
}

.errorbgColor {
    background-color: #fcd3D2;
}

.errorTextColor {
    color: #811211 !important
}

.successTextColor {
    color: #5e6919 !important
}


.modal_Property_And_Claim_Status {
    width: 820px !important;
}

.loaded-file {
    border: 1px solid lightgray;
    padding: 0.4rem;
    border-radius: 6px;
    background: #d3d3d329;
}

.filterBorder {
    border: 2px solid lightgray;
}

.leftAlign {
    text-align: left !important
}

.displayintablefor3lines {
    overflow: hidden;
    white-Space: normal;
    text-Overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    max-height: 5em;
    line-height: 1.5em;
    padding-top: 5;
    padding-bottom: 5;
    position: relative;
}

.modal-content .p-dropdown-filter-container {
    text-align: left !important;
}

.mail-forwards-filters {
    .p-autocomplete-input {
        border-right: solid 1px lightgray !important;
        width: 100% !important;
    }
}

.bg-opra {
    background-color: #def4fb;
}

.mail-fwd-link {
    color: blue;
    text-decoration: underline;
    cursor: pointer;
    font-weight: bolder;
}

.mail-fwd-details {
    .nav-link {
        color: black !important;
    }
}

.label-mailF-details {

    font-weight: bolder;
}

.label-mailF-colour {
    color: #215f9a
}

.mail-fwd-integrity {
    .border-line {
        border-bottom: 3px solid #3475aa !important;
    }

    .dueHeader {
        color: #3475aa !important;
        font-weight: bolder;
    }

    #custom-editor-uploader {
        display: none
    }

    .custom-upload-button {
        color: white;
        border: 2px solid #354f6e;
        border-radius: 5px;
        padding: 0.35rem;
        cursor: pointer;
        font-weight: bolder;
        background-color: #354f6e;
        line-height: unset;

        &:hover {
            background-color: #354f6e;
            color: white;
        }
    }
}

body .ps_v2_radio_button .p-radiobutton .p-radiobutton-box.p-highlight {
    background-color: #4EA250 !important;
    border-color: #4EA250 !important;
    color: #ffffff !important;
}

.common-deal-auto-complete {
    .p-autocomplete-input {
        border-right: solid 1px lightgray !important;
        width: 100% !important;
    }
}

.width-max-content {
    width: max-content !important;
}

.tableCardBody .pv_v2_header input.p-inputtext.p-component.p-autocomplete-input.p-autocomplete-dd-input {
    width: calc(100% - 2.357em) !important;
}

body .pv_v2_header .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
    background-color: #4EA250 !important;
    border-color: #ffffff !important;
}

body .pv_v2_header .p-inputswitch .p-inputswitch-slider {
    background-color: #FF0000 !important;

}

body .bifurcationModal .p-datatable .p-datatable-tbody>tr>td {
    border: 1px solid #c8c8c8 !important;
    font-size: 12px !important;
}

.mail-fwd-details {
    .p-component:disabled {
        opacity: 0.8;
    }

    .p-dropdown {
        max-width: 100%;
    }

    .alert {
        padding: 0.5rem 3.25rem;
    }

    .alert-dismissible .close {
        padding: 0.5rem 1.25rem;
    }

    .resize-text-area {
        resize: both;
        width: 100%;
    }

    .mail-fwd-details-fields {
        label {
            text-align: right;
        }
    }

}

.mail-fwd-assignment {
    .p-column-title {
        text-transform: none !important;
    }
}

.mapped-prop-search-top-filters {
    .p-inputtext {
        max-width: 100% !important;
    }

    @media screen and (min-width:1920px) {
        .filter-buttons {
            width: 5vw
        }
    }
}