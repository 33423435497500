.modal {

}

.modal-dialog {
  max-width: 780px;
}
//
.modal-content {
  border-radius: 0px;
  border: none;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
  position: relative;
  padding: 20px 40px 10px 40px;
  text-align: center;

  @include themify($themes) {
    background-color: themed('colorBackground');
    color: themed('colorText');
  }
}

.modal__close-btn {
   position: absolute;
  top: 15px;
  right: 20px;
  font-size: 14px;
  width: 14px;
  height: 14px;
  cursor: pointer;
  padding: 0;
  border: none;
  background: transparent;


  @include themify($themes) {
    color: themed('colorIcon');
  }
}

.modal__title-icon {
  width: 24px;
  height: 24px;
  font-size: 24px;
}

.modal__title {
  margin-top: 10px;
  margin-bottom: 15px;

  &:first-child {
    margin-top: 0;
  }
}

.modal__footer {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  margin-bottom: 0;

  button {
    min-width: 100px;
    padding: 4px 25px;
    margin-bottom: 0;
  }
}

.modal-dialog--primary {

  .modal__title-icon {
    color: $color-template;
  }
}

.modal-dialog--success {

  .modal__title-icon {
    color: $color-accent;
  }
}

.modal-dialog--warning {

  .modal__title-icon {
    color: $color-yellow;
  }
}

.modal-dialog--danger {

  .modal__title-icon {
    color: $color-red;
  }
}

.modal-dialog--colored {
  color: white;

  .modal__title-icon {
    color: white;
  }

  .modal__close-btn {
    color: white;
  }

  .modal__footer {

    button:first-child {
      color: #ffffff;
      background-color: rgba(255, 255, 255, 0.3);
      border-color: #ffffff;

      &:before {
        background-color: rgba(255, 255, 255, 0.2);
      }
    }

    button:last-child {
      border-color: white;
      color: white;
    }
  }

  &.modal-dialog--primary .modal-content {
    background-color: $color-template;
  }

  &.modal-dialog--success .modal-content {
    background-color: $color-accent;
  }

  &.modal-dialog--warning .modal-content {
    background-color: $color-yellow;
  }

  &.modal-dialog--danger .modal-content {
    background-color: $color-red;
  }
}

.modal-dialog--header {
  max-width: 820px;

  .modal-content {
    padding: 0;
    text-align: left;
  }

  .modal__header {
    color: black;
    padding: 23px 20px;
    position: relative;
  }

  .modal__title {
    margin: 0;
    font-weight: 300;
    font-family: 'Inter',Helvetica,Arial,serif;
  }

  .modal__close-btn {
    color: $color-white;
    //top: calc(50% - 8px);
  }

  .modal__body {
    padding: 25px 40px 20px 20px;
  }
 
  .modal__footer {
    margin-bottom: 40px;
    justify-content: flex-end;
    padding-right: 20px;
  }

  &.modal-dialog--primary .modal__header {
    background-color: $color-template;
  }

  &.modal-dialog--success .modal__header {
    background-color: $color-accent;
  }

  &.modal-dialog--warning .modal__header {
    background-color: $color-yellow;
  }

  &.modal-dialog--danger .modal__header {
    background-color: $color-red;
  }
}
//.fade class shows modal right Side
	.modal.fade .modal-dialog{
		position: fixed;
		margin: auto;
		width: 380px;
		height: 100%;
		-webkit-transform: translate3d(0%, 0, 0);
		    -ms-transform: translate3d(0%, 0, 0);
		     -o-transform: translate3d(0%, 0, 0);
		        transform: translate3d(0%, 0, 0);
	}

  .userRequest{
    width: 30%!important;
  }
  @media screen and (max-width:350px){
	.modal.fade .modal-dialog{
    	width: 280px;
  }
  }

  @media screen and (min-width:2000px){
	.modal.fade .modal-dialog{
    	// width: 450px;
  }
  }
	.modal.left .modal-content,
	.modal.fade .modal-content {
		height: 100%;
		overflow-y: auto;
	}
	
  .modal.fade .modal-header {
	 background-color: cadetblue!important;
   border-top-left-radius: 0px;
   border-top-right-radius: 0px;
   color:white
	}
	
	.modal .fade.modal-body {
		padding: 15px 15px 80px;
	}


        
// /*Right*/
	.modal.fade .modal-dialog {
		right: 0px;
		-webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
		   -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
		     -o-transition: opacity 0.3s linear, right 0.3s ease-out;
		        transition: opacity 0.3s linear, right 0.3s ease-out;
	}
	
	.modal.fade.in .modal-dialog {
		right: 0;
	}