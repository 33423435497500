.scoring-table-container {
  width: 100%;
  font-family: Arial, sans-serif;
}

.table-title {
  text-align: center;
  color: #333;
}

.scoring-table {
  width: 100%;
  border-collapse: collapse;
  background-color: #f9f9f9;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.scoring-table th,
.scoring-table td {
  padding: 8px 12px;
  text-align: left;
  border: 1px solid #ddd;
}

.scoring-table th {
  background-color: #f2f2f2;
  color: #333;
  font-weight: bold;
}

.scoring-table td {
  font-size: 14px;
}

.scoring-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.scoring-table tr:hover {
  background-color: #e6e6e6;
  transition: background-color 0.3s ease;
}
