/* Table look changes start */

body .p-datatable .p-datatable-header,
body .p-datatable .p-datatable-footer {
    background-color: #fff !important;
    /* color: #333333; */
    /* border: 1px solid #c8c8c8; */
    padding: 0.571em 1em;
    text-align: center;
}



body .p-datatable .p-datatable-header,
body .p-datatable .p-datatable-footer {
    background-color: #ffffff;
    border: none;
    border-bottom: 0;
}

body .p-paginator {
    border: 1px solid #e5e5e5;
    background-color: #fff !important;
}

body .p-datatable .p-datatable-thead>tr>th {
    border: 1px solid #e5e5e5;
    background-color: #ffffff;
}

body .p-datatable .p-datatable-tbody>tr>td {
    border: none;
}


/* Table look changes end */

body .pi {
    font-size: 1em;
}

.imgStyles {
    width: 30px;
    height: 30px;
    margin-top: 0px;
    border-radius: 50%;
}

body .p-datatable .p-datatable-tbody tr.p-datatable-row.p-isEditedRow {
    background-color: #d4ecfa !important;
    // color: #ffffff;
}

body .p-datatable .p-datatable-tbody tr.p-datatable-row.p-deletedRow {
    background-color: #ffa500 !important;
    // color: #ffffff;
}

body .p-datatable .p-datatable-tbody tr.p-datatable-row.p-errordeletedRow {
    background-color: red !important;
    // color: #ffffff;
}

body .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    background-color: $color-blue;
    color: $color-white;
}

body .p-datatable .p-datatable-tbody>tr.p-highlight {
    background-color: #d4ecfa !important;
    color: #ffffff;
}

body .p-datatable .p-datatable-tbody tr.p-datatable-row {
    &:hover {
        background-color: #dddddd;
    }
}

body .p-datatable .p-datatable-tbody tr.p-datatable-row.p-highlightRow {
    background-color: #e9f7f1 !important;
    // color: #ffffff;
}

body .p-datatable .p-datatable-tbody tr.p-datatable-row.p-highlighteddarkRow {
    background-color: #90c9b0 !important;
    // color: #ffffff;
}

body .p-datatable .p-datatable-tbody tr.p-datatable-row.p-isEditedRow {
    background-color: #d4ecfa !important;
    // color: #ffffff;
}

body .p-datatable .p-datatable-tbody tr.p-datatable-row.p-deletedRow {
    background-color: #ffa500 !important;
    // color: #ffffff;
}

body .p-datatable .p-datatable-tbody tr.p-datatable-row.p-errordeletedRow {
    background-color: red !important;
    // color: #ffffff;
}

body .p-datatable .p-datatable-tbody tr.p-datatable-row.p-highlight.p-highlightRow {
    background-color: #354f6e !important // color: #ffffff;
}

body .p-datatable .p-datatable-tbody tr.p-datatable-row.p-revokedRow {
    background-color: rgb(111, 164, 178) !important;
    // color: #ffffff;
}

body .p-datatable-loading-content {
    position: absolute;
    left: 50% !important;
    top: 50%;
    z-index: 2;
    margin-top: -1em;
    margin-left: -1em;
    display: none;
}

body .p-datatable .p-datatable-tbody>tr>td {
    background: inherit;
    // border: 1px solid #c8c8c8;
    // padding: 0.1em 0.57em 0.1em 1.57;
    //  padding: 0.1em 1.57em;
    // padding: 0.1em 0.857em;


}

body .p-datatable .p-datatable-tbody>tr {
    // height: 40px !important;
}